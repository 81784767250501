import MyApps from "../MyApps";
import MyCourses from "../MyCourses";
import News from '../News';
import Notifications from "../Notifications";
import Announcements from "../Announcements";
import CourseTutoring from "../CourseTutoring";
import MySchedule from "../MySchedule";
import Widget from "../common/Widget";
import MyPrograms from "../MyPrograms";
import UniversityLibraries from "../UniversityLibraries";
import PlacesAndHours from "../PlacesAndHours";
import SystemStatus from "../SystemStatus";
import Safety from "../Safety";
import MyBookmarks from "../MyBookmarks";
import MyContacts from "../MyContacts";
import MyDining from "../MyDining";
import MyBalances from "../MyBalances";
import ItsHelp from '../ItsHelp';
import Resources from "../Resources";
import MyRegistration from "../MyRegistration";
import myAdvisingTeam from "../MyAdvisingTeam";
import {ResourceLinks, EvhscResourceLinks} from '../Resources/config';

export const WidgetMapping = {
    'widget__notifications': Notifications,  //decomissioned for now
    'widget__myApps': MyApps, 
    'widget__myCourses': MyCourses, 
    'widget__myNews': News, 
    'widget__announcements': Announcements, 
    'widget__courseTutoring': CourseTutoring,
    'widget__mySchedule': MySchedule, 
    'widget__myRegistration': MyRegistration, 
    'widget__myBalances': MyBalances,
    'widget__myPrograms': MyPrograms, 
    'widget__myAdvisingTeam': myAdvisingTeam, 
    'widget__universityLibraries': UniversityLibraries, 
    'widget__placesAndHours': PlacesAndHours,
    'widget__systemStatus': SystemStatus, 
    'widget__safety': Safety, 
    'widget__myBookmarks': MyBookmarks, 
    'widget__myContacts': MyContacts, 
    'widget__myDining': MyDining,    
    'widget__itsHelp': ItsHelp, 
    'widget__resources': Resources,
    'widget__evhscResources': Resources
}

export const WidgetProps = {
    'widget__myApps': {
        title: ["my", "Apps"],
        isTitleStylized: true
    },
    'widget__myCourses': {
        title: ["my", "Courses"],
        isTitleStylized: true
    },
    'widget__myNews': {
        title: ["my", "News"],
        isTitleStylized: true
    },
    'widget__announcements': {
        title: "Announcements",
        isTitleStylized: false
    },
    'widget__courseTutoring': {
        title: "Course Tutoring",
        isTitleStylized: false
    },
    'widget__mySchedule': {
        title: ["Up Next"],
        isTitleStylized: false
    },
    'widget__myRegistration': {
        title: ["my", "Registration"],
        isTitleStylized: true
    },
    'widget__myBalances': {
        title: ["my", "Balances"],
        isTitleStylized: true
    },
    'widget__myPrograms': {
        title: ["my", "Programs"],
        isTitleStylized: true
    },
    'widget__myAdvisingTeam': {
        title: ["my", "Advising", "Team"],
        isTitleStylized: true
    },
    'widget__universityLibraries': {
        title: "University Libraries",
        isTitleStylized: false
    },
    'widget__placesAndHours': {
        title: "Places & Hours",
        isTitleStylized: false
    },
    'widget__systemStatus': {
        title: "System Status",
        isTitleStylized: false
    },
    'widget__safety': {
        title: "Safety",
        isTitleStylized: false
    },
    'widget__myBookmarks': {
        title: ["my", "Bookmarks"],
        isTitleStylized: true
    },
    'widget__myContacts': {
        title: ["my", "Contacts"],
        isTitleStylized: true
    },
    'widget__myDining': {
        title: ["my", "Dining"],
        isTitleStylized: true
    },
    'widget__itsHelp': {
        title: "ITS Help",
        isTitleStylized: false
    },
    'widget__resources': {
        title: "Resources",
        isTitleStylized: false,
        resourceLinks: ResourceLinks
    },
    'widget__evhscResources': {
        title: "VHS Resources",
        isTitleStylized: false,
        resourceLinks: EvhscResourceLinks
    },
}

export const SectionParams = {
    0: {
        xl: 5,
        lg: 12, 
        md: 12, 
        className: "px-0 "
    },
    1: {
        xl: 4, 
        lg: 8, 
        md: 6, 
        className: "px-0 pt-3 px-xl-3 pt-xl-0 pe-lg-3 pe-md-3"
    },
    2: {
        xl: 3,
        lg: 4, 
        md: 6, 
        className: "px-0 pt-3 pt-xl-0"
    }
}
