import React, {Component} from 'react';
import {Col, Row, Container} from 'reactstrap';
import {Typography, Snackbar} from '@mui/material';
import {BreakpointBoxes} from '../common/BreakpointBoxes';
import {connect} from 'react-redux';
import {DefaultWidgetOrder, WidgetConfigs} from '../SetUp/config';
import {SectionParams} from './config';
import Section from './Section';
import {savePreferences} from '../SetUp/actions';
import {myPreferences} from '../../DataAccessLayer/services';
import {getData, putData} from '../../DataAccessLayer';
import {getUser, isUserAdmin, isUserMedical} from '../Layout/utils';
import SetUp from '../SetUp';
import FinancialAid2024Fall from "./Banners/FinancialAid2024Fall";

class Dashboard extends Component {
    state = {
        selectedSection: 0,
        selectedItem: ''
    };

    dragEnd = result => {
        this.setState({
            selectedSection: 0,
            selectedItem: ''
        });
        if (result?.destination?.droppableId) {
            let widgetsOrder =
                this.props?.preferences?.Widgets?.WidgetsOrder ||
                DefaultWidgetOrder;
            let sectionId = result.destination.droppableId;

            const [removedWidgetId] = widgetsOrder[sectionId - 1].splice(
                result.source.index,
                1
            );
            widgetsOrder[sectionId - 1].splice(
                result.destination.index,
                0,
                removedWidgetId
            );

            let preferences = this.props.preferences;
            preferences.Widgets = {
                ...preferences.Widgets,
                WidgetsOrder: widgetsOrder
            };

            putData(myPreferences, {
                preferences,
                midas: getUser(this.props.user, this.props.impersonation).midas
            })
            .then(_ => {
                this.props.savePreferences(preferences);
                this.loadPreferences();
            })
            .catch(err => console.log(err));
        }
    };

    dragStart = result => {
        this.setState({
            selectedSection: result.source.droppableId,
            selectedItem: result.draggableId
        });
    };

    loadPreferences = () => {
        getData(
            myPreferences +
                '/' +
                (getUser(this.props.user, this.props.impersonation).midas),
            true
        )
        .then(preferences => {
            this.props.savePreferences(preferences);
        })
        .catch(err => {
            console.log(err);
        });
    }

    render() {

        const user = getUser(this.props.user, this.props.impersonation);

        const isMedicalUser = isUserMedical(user);

        if (!Object.keys(this.props.preferences).length) {
            return <SetUp />;
        } else {
            const widgetOrder =
                this.props?.preferences?.Widgets?.WidgetsOrder ||
                DefaultWidgetOrder;
                
            const isAdmin = isUserAdmin(this.props.user);
            return (
                <React.Fragment>
                    {isAdmin && <BreakpointBoxes />}
                    <Typography
                        component="h2"
                        className="visually-hidden sr-only"
                    >
                        Dashboard
                    </Typography>
                    <Snackbar
                        open={this.state.selectedSection > 0}
                        message={
                            'Widgets may be dragged and dropped within their column'
                        }
                    />
                    <Container fluid className="myOdu__dashboard maxWidth mt-0 px-2 px-sm-0">
                        <Row className="pt-2">
                            {Object.values(SectionParams).map((params, idx) => {
                                return (
                                    <Col
                                        {...params}
                                        key={'dashboardColumn_' + idx}
                                    >
                                        <Section
                                            widgetsOrder={widgetOrder[idx]}
                                            widgets={WidgetConfigs.Widgets}
                                            sectionNumber={idx + 1}
                                            selectedSection={
                                                this.state.selectedSection
                                            }
                                            isDroppable={
                                                Number(
                                                    this.state.selectedSection
                                                ) > 0 &&
                                                Number(
                                                    this.state.selectedSection
                                                ) !==
                                                    idx + 1
                                            }
                                            isDragging={
                                                this.state.selectedSection > 0
                                            }
                                            selectedItem={
                                                this.state.selectedItem
                                            }
                                            dragEnd={this.dragEnd}
                                            dragStart={this.dragStart}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </Container>
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = state => {
    return {
        preferences: state.preferencesReducer.preferences,
        user: state.AWSReducer.user,
        isImpersonating:
            state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    };
};

const mapDispatchToProps = dispatch => ({
    savePreferences: preferences => dispatch(savePreferences(preferences))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
