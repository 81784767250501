import { SAVE_MY_COURSES_TAKING, SAVE_MY_COURSES_TEACHING } from "./actions";


const initialStateMyCourses = {
    terms: {"CURRENT_TERM":"202320","NEXT_TERM":"202330","NEXT_NEXT_TERM":"202410"},
    coursesTaking: {},
    coursesTeaching: {}
}

//needs to persist across pages
export const myCoursesReducer = (state = initialStateMyCourses, action) => {
    const { type, payload } = action;
    switch (type) {
        case SAVE_MY_COURSES_TAKING: {
            return {...state, coursesTaking: payload.coursesTaking}
        }
        case SAVE_MY_COURSES_TEACHING: {
            return {...state, coursesTeaching: payload.coursesTeaching}
        }
        default:
            return state;
    }
}