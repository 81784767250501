import { DataGrid } from "@mui/x-data-grid";
import React, { Component } from "react";

class PortalDataGrid extends Component {

    
    state = {
    };

    render() {
        return <React.Fragment>
            <DataGrid
                id={this.props.id}
                density="compact"
                experimentalFeatures={{ ariaV7: true }} //for ADA
                rows={this.props.rows}
                columns={this.props.columns}
                initialState={this.props.initialState}
                pageSizeOptions={[10, 20]}
                onRowClick={this.props.handleClick}
                getRowId={this.props.getRowId}
                loading = {this.props.isLoading}
                slots={this.props.slots}
                columnVisibilityModel = {this.props.columnVisibilityModel}
                slotProps={this.props.slotProps}
                disableColumnFilter = {this.props.disableColumnFilter}
                disableColumnSelector = {this.props.disableColumnSelector}
                disableDensitySelector = {this.props.disableDensitySelector}
            />
        </React.Fragment>
    }
}

export default PortalDataGrid;