import {TOGGLE_ALL_CATEGORIES, TOGGLE_CATEGORY} from './actions';

const initialState = {
  expandedCategories: {}
};

export const placesAndHoursReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_ALL_CATEGORIES:
      return {
        ...state,
        expandedCategories: action.payload.expandedCategories || {}
      };
    case TOGGLE_CATEGORY:
      return {
        ...state,
        expandedCategories: action.payload.expandedCategories || {}
      };
    default:
      return state;
  }
};
