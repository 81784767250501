// Action Types
export const TOGGLE_CATEGORY = 'TOGGLE_CATEGORY';
export const TOGGLE_ALL_CATEGORIES = 'TOGGLE_ALL_CATEGORIES';

// Action Creators
export const toggleCategory = expandedCategories => ({
  type: TOGGLE_CATEGORY,
  payload: {expandedCategories}
});

export const toggleAllCategories = expandedCategories => {
  return {
    type: TOGGLE_ALL_CATEGORIES,
    payload: {expandedCategories}
  };
};
