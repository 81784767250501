import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import {connect} from 'react-redux';
import {postData} from '../../../../../../DataAccessLayer';
import {cctClass} from '../../../../../../DataAccessLayer/services';
import {toast} from 'react-toastify';
import {ZoomTabMapping} from '../config';
import {MeetingDateFormat, OptionsMapping, RecurringOptions} from './config';
import {
    DayMapping,
    monthsList,
    zoomDevEmail
} from '../../../../../common/constants';
import {FormTextField} from '../../../../../common/Form/FormTextField';
import {FormCheckbox} from '../../../../../common/Form/FormCheckbox';
import {DatePicker} from '@mui/x-date-pickers';
import {
    FormControlledTimePicker,
    FormTimePicker
} from '../../../../../common/Form/FormTimePicker';
import {FormButton} from '../../../../../common/Form/FormButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/pro-light-svg-icons';
import {FormDatePicker} from '../../../../../common/Form/FormDatePicker';
import {getUserEmail} from './utils';
import {Time} from '../../../../../common/MomentTime';
import PortalTime from '../../../../../common/PortalTime';
import PortalTooltip from '../../../../../common/PortalTooltip';
import {WithSmallScreen} from '../../../../../common/WithSmallScreen';

class ClassMeetings extends Component {
    state = {
        isLoading: false,
        isCustomDate: false,
        customStartDate: dayjs(this.props.course?.START_DATE),
        customEndDate: dayjs(this.props.course?.END_DATE),
        customStartTime: dayjs(
            this.props.course?.START_DATE + 'T' + this.props.course?.BEGIN_TIME
        ),
        customEndTime: dayjs(
            this.props.course?.START_DATE + 'T' + this.props.course?.END_TIME
        ),
        [OptionsMapping.isRecordMeeting]: true,
        [OptionsMapping.isPublishToCanvas]: false,
        [OptionsMapping.isJoinBeforeHost]: false,
        [OptionsMapping.isHideMeeting]: false,
        isExpand: false,
        weekDays: {},
        expandedCourses: {}
    };

    componentDidMount() {
        this.createWeekDays();
    }

    //Θ(N) where N is the number of weekdays the course meeting is scheduled on
    //Maps weekday code to full weekday name.
    createWeekDays = () => {
        if (this.props?.course && this.props.course?.isClassScheduled) {
            let weekDays = [...this.props.course?.MEETING_DAYS]
                .map(shortWeekDay => {
                    let longWeekDay = DayMapping[shortWeekDay];
                    return RecurringOptions.weekly.weekdays[
                        longWeekDay.toLowerCase()
                    ]?.name;
                })
                .reduce((weekdays, newWeekday) => {
                    return {...weekdays, [newWeekday]: true};
                }, {});
            this.setState({
                weekDays
            });
        }
    };

    toggleIsLoading = (isLoading = !this.state.isLoading) => {
        this.setState({
            isLoading
        });
    };

    toggleIsExpand = (isExpand = !this.state.isExpand) => {
        this.setState({
            isExpand
        });
    };

    //Θ(1) sets start and end dates
    setCustomDates = value => {
        let customStartDate = value[0];
        let customEndDate = value[1];

        if (customStartDate && customEndDate) {
            this.setState({
                customStartDate,
                customEndDate
            });
        }
    };

    //Θ(1) sets dates
    setDate = (value, name) => {
        this.setState({
            [name]: value
        });
    };

    //Θ(1) updates values of respective checkboxes
    onCheckbox = event => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    };

    //Θ(1) returns the topic
    getTopic = course => {
        let crns = course?.CRNS?.split(',');

        if (crns?.length)
            return (
                course.SSBSECT_TERM_CODE +
                '_' +
                course.SUBJECT_CODE +
                '_' +
                course.COURSE_NUMBER +
                '_' +
                crns[0]
            );
        else return '';
    };

    //Θ(1) returns the topic to be displayed in input
    getDisplayTopic = course => {
        let crns = course?.CRNS?.split(',');

        if (crns?.length)
            return (
                course.SUBJECT_CODE +
                ' ' +
                course.COURSE_NUMBER +
                ' Class Meetings'
            );
        else return '';
    };

    /**
     * O(1)
     * Toggles the expansion of course details based on its meeting days and times.
     *
     * @param {Object} course - The course to be expanded or collapsed.
     */
    toggleExpandCourse = course => {
        const key = `${course.MEETING_DAYS}-${course.TIMES}`;

        // Get the current state
        const expandedCourses = {...this.state.expandedCourses};

        if (expandedCourses[key]) {
            delete expandedCourses[key]; // Collapse the course details
        } else {
            expandedCourses[key] = true; // Expand the course details
        }

        // Update the state
        this.setState({expandedCourses});
    };

    //Θ(N) where N is the length of meeting days
    //Returns class meeting details in short
    getClassShortDetails = course => {
        const schedule = course?.SCHEDULE;
        if (schedule) {
            return schedule.map(entry => {
                let details = '';
                if (course?.CRNS && entry?.MEETING_DAYS) {
                    const startDate = Time.Local(course.START_DATE);

                    const endDate = Time.Local(course.END_DATE);

                    const weekdays = [...entry?.MEETING_DAYS].reduce(
                        (weekday, shortWeekDay, index, array) => {
                            if (index === 0)
                                weekday += DayMapping[shortWeekDay];
                            else if (index === array.length - 1)
                                weekday += ' and ' + DayMapping[shortWeekDay];
                            else weekday += ', ' + DayMapping[shortWeekDay];

                            return weekday;
                        },
                        ''
                    );

                    const courseWeekDays = [...entry?.MEETING_DAYS]
                        .map(shortWeekDay => {
                            let longWeekDay = DayMapping[shortWeekDay];
                            return RecurringOptions.weekly.weekdays[
                                longWeekDay.toLowerCase()
                            ]?.name;
                        })
                        .reduce((weekdays, newWeekday) => {
                            return {...weekdays, [newWeekday]: true};
                        }, {});

                    const timeFormat = 'h:mm A';

                    const startTime = dayjs(
                        course?.START_DATE + 'T' + entry?.BEGIN_TIME
                    );
                    const formattedStartTime = startTime?.format(timeFormat);

                    const endTime = dayjs(
                        course?.START_DATE + 'T' + entry?.END_TIME
                    );
                    const formattedEndTime = endTime?.format(timeFormat);

                    const key = `${entry.MEETING_DAYS}-${entry.TIMES}`;

                    const isExpanded = !!this.state.expandedCourses[key];

                    return (
                        <>
                            <Stack direction="row">
                                <FontAwesomeIcon
                                    icon={
                                        isExpanded ? faChevronUp : faChevronDown
                                    }
                                    size="lg"
                                    onClick={() => {
                                        this.toggleExpandCourse(entry);
                                    }}
                                    fixedWidth
                                    id={
                                        'classDetails__chevron_accordionSummary'
                                    }
                                />
                                <Grid container direction="row">
                                    <Grid item xs={11} sm="auto">
                                        <Typography sx={{ml: 1}}>
                                            {`${
                                                monthsList[startDate.month()]
                                            } ${startDate.date()} - ${
                                                monthsList[endDate.month()]
                                            } ${endDate.date()}, `}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{ml: 1}}>
                                            {weekdays}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography sx={{ml: 1}}>
                                            <Stack direction="row">
                                                <PortalTime
                                                    time={formattedStartTime}
                                                />
                                                &nbsp; - &nbsp;
                                                <PortalTime
                                                    time={formattedEndTime}
                                                />
                                            </Stack>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Stack>

                            {isExpanded && (
                                <>
                                    <Row style={{padding: 4}}>
                                        <Grid
                                            container
                                            spacing={1}
                                            alignItems={'center'}
                                        >
                                            <Grid
                                                item
                                                // xs={10}
                                                md={1.5}
                                                textAlign={{
                                                    xs: 'start',
                                                    md: 'end'
                                                }}
                                            >
                                                <Typography
                                                    className="myOdu__label"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    Dates
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                container
                                                xs={12}
                                                md={10}
                                                wrap="nowrap"
                                                spacing={1}
                                            >
                                                <Grid item xs={6}>
                                                    {/* TODO: Create a DatePicker component */}
                                                    <FormDatePicker
                                                        value={startDate}
                                                        disabled
                                                        slotProps={{
                                                            textField: {
                                                                size: 'small',
                                                                fullWidth: true
                                                            }
                                                        }}
                                                        inputGridProps={{
                                                            xs: 12
                                                        }}
                                                        labelGridProps={{
                                                            xs: 0
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={'auto'}
                                                    textAlign={'center'}
                                                    alignContent={'center'}
                                                >
                                                    <Typography variant="p">
                                                        To
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormDatePicker
                                                        value={endDate}
                                                        disabled
                                                        slotProps={{
                                                            textField: {
                                                                size: 'small',
                                                                fullWidth: true
                                                            }
                                                        }}
                                                        inputGridProps={{
                                                            xs: 12
                                                        }}
                                                        labelGridProps={{
                                                            xs: 0
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Row>
                                    <Row style={{padding: 4}}>
                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                // xs={12}
                                                md={1.5}
                                                textAlign={{
                                                    xs: 'start',
                                                    md: 'end'
                                                }}
                                                style={{
                                                    paddingTop: this.props
                                                        .isSmall
                                                        ? 8
                                                        : 16
                                                }}
                                            >
                                                <Typography className="myOdu__label">
                                                    Time
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                container
                                                xs={12}
                                                md={10}
                                                spacing={1}
                                                wrap="nowrap"
                                            >
                                                <Grid item xs={6}>
                                                    <FormTimePicker
                                                        name={'startTime'}
                                                        value={startTime}
                                                        disabled
                                                        inputGridProps={{
                                                            xs: 12
                                                        }}
                                                        shouldRespectLeadingZeros={
                                                            true
                                                        }
                                                        helperText={
                                                            <PortalTooltip
                                                                title={
                                                                    "Meetings are always scheduled in ODU's timezone which changes between Eastern Standard Time and Eastern Daylight Time"
                                                                }
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    variant="p"
                                                                    className="myOdu__universityTime"
                                                                >
                                                                    University
                                                                    Timezone
                                                                </Typography>
                                                            </PortalTooltip>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={'auto'}
                                                    textAlign={'center'}
                                                    style={{paddingTop: 16}}
                                                >
                                                    <Typography variant="p">
                                                        To
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormTimePicker
                                                        name={'endTime'}
                                                        value={endTime}
                                                        disabled
                                                        inputGridProps={{
                                                            xs: 12
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Row>
                                    <Row style={{padding: 4}}>
                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                // xs={12}
                                                md={1.5}
                                                textAlign={{
                                                    xs: 'start',
                                                    md: 'end'
                                                }}
                                            >
                                                <Typography className="myOdu__label">
                                                    Days
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={10}
                                                alignItems={'center'}
                                            >
                                                {Object.entries(
                                                    RecurringOptions.weekly
                                                        .weekdays
                                                ).map(([key, value]) => {
                                                    return (
                                                        <FormCheckbox
                                                            label={value.name}
                                                            labelGridProps={{
                                                                xs: 0
                                                            }}
                                                            inputGridProps={{
                                                                xs: 12,
                                                                md: 9,
                                                                lg: 8,
                                                                xl: 9
                                                            }}
                                                            value={
                                                                courseWeekDays[
                                                                    value.name
                                                                ]
                                                            }
                                                            disabled
                                                            sx={{
                                                                marginRight:
                                                                    '0.25rem'
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Row>
                                </>
                            )}
                        </>
                    );
                }

                return details;
            });
        }

        return [];
    };

    //Θ(1) Creates a meeting by hitting backend api
    onSchedule = () => {
        this.toggleIsLoading(true);
        let course = this.props.course;

        let topic = this.getTopic(course);
        let agenda = 'Meeting for ' + course.CRNS;
        let record = this.state.isRecordMeeting;
        let joinBeforeHost = this.state.isJoinBeforeHost;
        let hideMeetings = this.state[OptionsMapping.isHideMeeting];
        let preferredStartDate = Time.Local(
            this.state.customStartDate['$d']
        ).format(MeetingDateFormat);
        let preferredEndDate = Time.Local(
            this.state.customEndDate['$d']
        ).format(MeetingDateFormat);
        let publishKaltura = this.state.isPublishToCanvas;

        let email = getUserEmail(this.props.user, this.props.impersonation);

        let meetingDetails = {
            crns: course.CRNS,
            termCode: course.SSBSECT_TERM_CODE,
            topic,
            agenda,
            email,
            publishKaltura,
            record,
            joinBeforeHost,
            hideMeetings
            //We dont need to send custom dates in new portal
            // ...(this.state.isCustomDate && {
            //     preferredStartDate,
            //     preferredEndDate
            // }) //Dates added only if Custom dates checkbox checked
        };

        postData(cctClass, meetingDetails, true)
            .then(result => {
                if (!result) toast.error('Unable to create meetings');
                else {
                    this.props.onTabChange('', ZoomTabMapping.currentMeeting);
                    this.props.toggleIsScheduleMeeting(false);
                    toast.success('Meetings Created');
                }
                this.toggleIsLoading(false);
            })
            .catch(err => {
                this.toggleIsLoading(false);
                toast.error('Unable to create meeting');
                console.log(err);
            });
    };

    render() {
        let course = this.props.course;
        let topic = this.getDisplayTopic(course);

        return (
            <React.Fragment>
                <Box sx={{marginTop: 2}}>
                    <Row>
                        <Col>
                            <Typography variant="h4" component="h4">
                                Schedule Class Meetings
                            </Typography>
                        </Col>
                    </Row>
                    <Box sx={{padding: '1rem'}}>
                        <Row style={{padding: 4}}>
                            <FormTextField
                                id="meeting_title"
                                label="Meeting Title"
                                value={topic}
                                disabled
                                inputGridProps={{xs: 12, md: 10}}
                                labelGridProps={{xs: 12, md: 2}}
                                textAlign={{xs: 'start', md: 'end'}}
                            />
                        </Row>

                        <Row style={{padding: 4}}>
                            <Row style={{width: '100%'}}>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        textAlign={'start'}
                                    >
                                        <Grid
                                            container
                                            justifyContent={{
                                                xs: 'start',
                                                md: 'end'
                                            }}
                                        >
                                            <Grid xs="auto" item>
                                                <Typography className="myOdu__label">
                                                    Class Details
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        {this.getClassShortDetails(course)}
                                    </Grid>
                                </Grid>
                            </Row>
                        </Row>

                        <Row style={{padding: 4}}>
                            <Row style={{width: '100%'}}>
                                <Grid container spacing={1}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        textAlign={{xs: 'start', md: 'end'}}
                                    >
                                        <Typography className="myOdu__label">
                                            Options
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        alignItems="center"
                                        xs={12}
                                        md={10}
                                    >
                                        <FormCheckbox
                                            label={'Record Meeting'}
                                            onChange={this.onCheckbox}
                                            name={
                                                OptionsMapping.isRecordMeeting
                                            }
                                            labelGridProps={{
                                                xs: 0
                                            }}
                                            inputGridProps={{
                                                xs: 12,
                                                md: 9,
                                                lg: 8,
                                                xl: 9
                                            }}
                                            value={
                                                this.state[
                                                    OptionsMapping
                                                        .isRecordMeeting
                                                ]
                                            }
                                            sx={{marginRight: '0.25rem'}}
                                        />
                                        <FormCheckbox
                                            label={
                                                'Automatically Publish Recording to Canvas'
                                            }
                                            onChange={this.onCheckbox}
                                            name={
                                                OptionsMapping.isPublishToCanvas
                                            }
                                            labelGridProps={{
                                                xs: 0
                                            }}
                                            inputGridProps={{
                                                xs: 12,
                                                md: 9,
                                                lg: 8,
                                                xl: 9
                                            }}
                                            value={
                                                this.state[
                                                    OptionsMapping
                                                        .isPublishToCanvas
                                                ]
                                            }
                                            sx={{marginRight: '0.25rem'}}
                                        />
                                        <FormCheckbox
                                            label={
                                                'Allow Participants to Join Before Host'
                                            }
                                            onChange={this.onCheckbox}
                                            name={
                                                OptionsMapping.isJoinBeforeHost
                                            }
                                            labelGridProps={{
                                                xs: 0
                                            }}
                                            inputGridProps={{
                                                xs: 12,
                                                md: 9,
                                                lg: 8,
                                                xl: 9
                                            }}
                                            value={
                                                this.state[
                                                    OptionsMapping
                                                        .isJoinBeforeHost
                                                ]
                                            }
                                            sx={{marginRight: '0.25rem'}}
                                        />
                                        <FormCheckbox
                                            label={'Hide Meeting from Students'}
                                            onChange={this.onCheckbox}
                                            name={OptionsMapping.isHideMeeting}
                                            labelGridProps={{
                                                xs: 0
                                            }}
                                            inputGridProps={{
                                                xs: 12,
                                                md: 9,
                                                lg: 8,
                                                xl: 9
                                            }}
                                            value={
                                                this.state[
                                                    OptionsMapping.isHideMeeting
                                                ]
                                            }
                                            sx={{marginRight: '0.25rem'}}
                                        />
                                    </Grid>
                                </Grid>
                            </Row>
                        </Row>
                    </Box>
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item xs={12} md={'auto'}>
                            <FormButton
                                label={'Cancel'}
                                id="backToScheduleMeeting"
                                onClick={this.props.onToggle}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={'auto'}>
                            <Button
                                onClick={this.onSchedule}
                                disabled={this.state.isLoading}
                                variant="outlined"
                                className="myOdu__button scheduleMeetings primary"
                                fullWidth
                            >
                                Schedule Class Meetings
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        course: state.CCTReducer.course,
        user: state.AWSReducer.user,
        impersonation: state.impersonationReducer.impersonation,
        meetings: state.CCTReducer.meetings
    };
};

export default connect(mapStateToProps)(WithSmallScreen(ClassMeetings));
