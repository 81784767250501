export const AppsTabMapping = {
    ODU: 0,
    EVMS: 1,
    ALL: 2
};

export const AppConfigs = {
    Version: 2.6,
    Apps: [
        {
            id: 'apply',
            name: 'Apply to ODU',
            icon: 'apply.svg',
            desc: 'Users can apply for undergraduate and graduate admission to ODU.',
            link: 'https://www.odu.edu/apply',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'archibus',
            name: 'Archibus - ODU',
            icon: 'archibus.svg',
            desc: 'For ODU personnel - Submit campus maintenance requests and view ticket status.',
            preSelected: {isHousing: true},
            link: 'https://odu.nmrkgcs.com/archibus/workplace',
            linkTarget: '_blank',
            isODU: true,
        },
        {
            id: 'archibusVHS',
            name: 'Archibus - VHS',
            icon: 'archibus.svg',
            desc: 'For VHS personnel - this system makes available accurate online EVMS building floor plans for management and planning.',
            link: 'https://evms.oncfi.com/archibus/login.axvw',
            linkTarget: '_blank',
            isEVMS: true,
        },
        {
            id: 'banner',
            name: 'Banner',
            icon: 'banner.svg',
            desc: "Banner is the university's centralized academic and administrative records system.",
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'https://bannerxe.odu.edu:7005/applicationNavigator',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'billPay',
            name: 'Bill Payment',
            icon: 'billPay.svg',
            desc: 'Use this service to pay tuition and other ODU bills.',
            preSelected: {role: ['STUDENT']},
            link: 'https://www.odu.edu/quickfix/pay-tuition-students',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'canvas',
            name: 'Canvas',
            icon: 'canvas.svg',
            desc: "Canvas is ODU's web-based learning management system used by instructors and students to access and manage online course learning materials.",
            preSelected: {role: ['INSTFAC', 'STUDENT']},
            link: 'https://canvas.odu.edu/',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'chromeRiver',
            name: 'Chrome River',
            icon: 'chromeRiver.svg',
            desc: "Chrome River is ODU's travel and expense management tool, required for employee reimbursements.",
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'http://odu.edu/chromeriver',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'courseSearch',
            name: 'ODU Course Search',
            icon: 'courseSearch.svg',
            desc: 'The ODU course search tool is an intuitive platform that allows students to easily find and filter courses based on criteria such as subject, instructor, and schedule. It provides detailed course descriptions, prerequisites, and enrollment options to streamline the process of academic planning.',
            preSelected: {role: ['STUDENT']},
            link: 'https://courses.odu.edu/',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'degreeWorks',
            name: 'Degree Works',
            icon: 'degreeWorks.svg',
            desc: 'The Degree Works degree evaluation tool is designed to assist advisors and students with course planning, measuring progress toward program completion, and curriculum advising.',
            required: [{role: 'STUDENT'}],
            preSelected: {role: ['STUDENT','ADVISOR']},
            link: 'https://degree.odu.edu',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'deviceRegistration',
            name: 'Device Registration',
            icon: 'deviceRegistration.svg',
            desc: 'Register your game consoles and media devices here for DeviceODU Wi-Fi access.',
            preSelected: {isHousing: true},
            link: 'https://device.odu.edu/',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'duo',
            name: 'Duo',
            icon: 'duo.svg',
            desc: 'Duo is software that allows all users to perform two factor authentication to log in.',
            isDefaultSelected: true,
            link: 'https://www.odu.edu/information-technology-services/two-factor-authentication',
            linkTarget: '_blank',
            isODU: true, 
            isEVMS: true,
            preSelected: {role: ['EVHSC','ODU']}
        },
        {
            id: 'eva',
            name: 'eVA',
            icon: 'eva.svg',
            desc: 'eVA is the Commonwealth of Virginia\’s web-based procurement tool designed to enhance the efficiency of the procurement process.',
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'https://eva.virginia.gov/',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'finAidPortal',
            name: 'Financial Aid Portal',
            icon: 'financialAidPortal.svg',
            desc: 'Streamline your college funding process with our comprehensive financial aid portal.',
            preSelected: {role: ['STUDENT']},
            link: 'https://xessb.odu.edu:8443/StudentSelfService/ssb/financialAid',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'get',
            name: 'GET',
            icon: 'get.svg',
            desc: 'GET allows you to manage funds, make purchases, order food, open doors, make dining reservations, and earn rewards. You can use GET just like you use a plastic ID card.',
            preSelected: {role: ['STUDENT','FACULTY', 'STAFF']},
            link: 'https://get.cbord.com/odu/full/prelogin.php',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'gmail',
            name: 'Gmail',
            icon: 'gmail.svg',
            desc: 'Google Workspace for Education - Productivity and collaboration tools from Google.',
            // isDefaultSelected: true,
            link: 'https://mail.google.com/a/odu.edu',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'googleDrive',
            name: 'Google Drive',
            icon: 'googleDrive.svg',
            desc: 'Google Workspace for Education - Productivity and collaboration tools from Google.',
            isDefaultSelected: true,
            link: 'https://drive.google.com/a/odu.edu',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'handshake',
            name: 'Handshake',
            icon: 'handshake.svg',
            desc: 'Handshake is an online recruiting platform for higher education students and alumni. They partner with universities and employers to streamline and simplify the recruiting process.',
            // isDefaultSelected: true,
            preSelected: {role: ['STUDENT']},
            link: 'https://odu.joinhandshake.com/',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'itsHelpDesk',
            name: 'IT Help Desk',
            icon: 'itsHelpDesk.svg',
            desc: 'The IT help desk offers in-person, phone, and email support for IT problems troubling students, faculty, and staff.',
            isDefaultSelected: true,
            link: 'https://www.odu.edu/ts/helpdesk',
            linkTarget: '_blank',
            isODU: true,
            isEVMS: true,
        },
        {
            id: 'kaltura',
            name: 'Kaltura',
            icon: 'kaltura.svg',
            desc: 'Kaltura is a cloud-based video management system for storing, publishing and streaming videos, video collections and other media.',
            // isDefaultSelected: true,
            link: 'https://odumedia.mediaspace.kaltura.com/',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'kuali',
            name: 'Kuali',
            icon: 'kuali.svg',
            desc: 'Build combines advanced form automation software and sophisticated workflow automation software into one platform. Create powerful digital forms and automated approvals.',
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'https://odu.kualibuild.com/',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'largeFileTransfer',
            name: 'Large File Transfer',
            icon: 'largeFileTransfer.svg',
            desc: 'ZendTo is the safe, secure and completely free way to transfer large files around the Web.',
            // isDefaultSelected: true,
            link: 'https://lfs.odu.edu/',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'laundry',
            name: 'Laundry',
            icon: 'laundry.svg',
            desc: 'The Laundry app lets users see how many washers and dryers are available around campus, and even place machines on hold.',
            preSelected: {isHousing: true},
            link: 'https://cardcenter.odu.edu/student/laundry/welcome.php',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'leoOnline',
            name: 'LEO Online',
            icon: 'leoOnline.svg',
            desc: 'Leo Online is a secure, web-based self-service tool designed to allow students, faculty, and staff access to the tools and information required for doing business with the University, including registration, grades, and access to records associated with academic progress and employment.',
            isDefaultSelected: true,
            link: 'https://sso.odu.edu/ssomanager/c/SSB',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'lime',
            name: 'Lime',
            icon: 'lime.svg',
            desc: 'Lime E-Scooters and bicycles are dockless electric mobility share vehicles in a program piloted by the City of Norfolk.',
            preSelected: {campus: ['main']},
            link: 'https://www.odu.edu/transportation-parking-services/alternative-transportation-programs/scooters',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'liveSafe',
            name: 'Live Safe',
            icon: 'livesafe.svg',
            desc: 'LiveSafe enables direct and discreet two-way communication with your community safety officials using text, picture, video, and audio. It also lets you virtually walk your friends and family home with SafeWalk.',
            preSelected: {campus: ['main'], isHousing: true},
            link: 'http://www.odu.edu/life/health-safety/safety/livesafe',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'midas',
            name: 'MIDAS',
            icon: 'midas.svg',
            desc: 'MIDAS is a website for password resets, identity management, and provisioning services for your account.',
            link: 'http://midas.odu.edu/',
            linkTarget: '_blank',
            isODU: true,
            isEVMS: true,
        },
        {
            id: 'monarchAdvisingPortal',
            name: 'Monarch Advising Portal',
            icon: 'monarchAdvisingPortal.svg',
            desc: 'Monarch Advising Portal is a comprehensive platform designed for students to stay connected with their advisors, schedule appointments, and manage their academic progress. It offers a streamlined and user-friendly interface to ensure effective communication and support throughout their educational journey.',
            preSelected: {role: ['STUDENT', 'ADVISOR']},
            link: 'https://olddominionuniversity8.my.site.com/studentportal',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'monarchGroups',
            name: 'Monarch Groups',
            icon: 'monarchGroups.svg',
            desc: 'Monarch Groups is the ODU hub on campus groups, where people can connect over events or similar interests.',
            preSelected: {role: ['STUDENT']},
            link: 'https://odu.campusgroups.com',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'monarchMovies',
            name: 'Monarch Movies',
            icon: 'monarchMovies.svg',
            desc: 'A free movie-streaming service for anyone physically at ODU.',
            preSelected: {isHousing: true},
            link: 'https://monarchmovies.odu.edu/#/browse',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'monarchPrint',
            name: 'Monarch Print',
            icon: 'monarchPrint.svg',
            desc: 'Upload or email your document(s) to the MonarchPrint cloud and pick up your job from any campus printer.',
            isDefaultSelected: true,
            link: 'https://monarchprint.odu.edu',
            linkTarget: '_blank',
            isODU: true
        },

        {
            id: 'monarchWellnessPortal',
            name: 'Wellness Portal',
            icon: 'monarchWellnessPortal.svg',
            desc: 'Visit the Monarch Wellness Portal to make healthcare appointments, manage immunization records, etc.',
            isDefaultSelected: true,
            link: 'https://odu.medicatconnect.com',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'move',
            name: 'MOVE',
            icon: 'move.svg',
            desc: "MOVE is ODU's virtual desktop environment which gives users a computer lab experience from anywhere.",
            isDefaultSelected: true,
            link: 'http://move.odu.edu/',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'oduJobs',
            name: 'ODU Jobs',
            icon: 'oduJobs.svg',
            desc: 'ODU Jobs lets you browse and apply for open faculty and staff positions at ODU.',
            // isDefaultSelected: true,
            link: 'https://jobs.odu.edu/',
            preSelected: {role: ['FACULTY', 'STAFF']},
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'oduMobile',
            name: 'ODU Mobile',
            icon: 'oduMobileIcon.svg',
            desc: 'ODUMobile is a free mobile application geared toward providing information on the go and allows users access to essential Old Dominion University information anytime on their mobile device.',
            isDefaultSelected: true,
            link: 'https://ww1.odu.edu/ts/software-services/odumobile',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'office365',
            name: 'Office 365',
            icon: 'office.svg',
            desc: 'Office 365 is an office suite of products that include cloud collaboration tools and applications, provided for no cost, as long as users are a part of the University.',
            isDefaultSelected: true,
            link: 'http://office365.odu.edu/',
            linkTarget: '_blank',
            isODU: true,
            isEVMS: true,
        },
        {
            id: 'opinionSurveys',
            name: 'Opinion Surveys',
            icon: 'studentOpinionSurvey.svg',
            desc: 'Take anonymous surveys surveys to give us important feedback on your semester, which helps improve teaching and coursework.',
            preSelected: {role: ['STUDENT']},
            link: 'https://www.odu.edu/academics/courses-registration/studentopinionsurvey',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'outlook',
            name: 'Outlook',
            icon: 'outlook.svg',
            desc: 'Old Dominion University uses Microsoft Outlook as the official e-mail system for distributing course-related communications, policies, announcements and other information.',
            isDefaultSelected: true,
            link: 'http://outlook.odu.edu/',
            linkTarget: '_blank',
            isODU: true,
            isEVMS: true,
        },
        {
            id: 'papers',
            name: 'Papers',
            icon: 'papers.svg',
            desc: "PAPERS is ODU's online recruitment, position management and performance management system.",
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'https://jobs.odu.edu/hr',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'parkingPortal',
            name: 'Parking Portal',
            icon: 'parkingPortal.svg',
            desc: 'ODU Parking Portal allows users to purchase virtual permits, update parking account information and pay outstanding citations.',
            preSelected: {campus: ['main'], isHousing: true},
            link: 'https://odu.t2hosted.com/Account/Portal',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'qualtrics',
            name: 'Qualtrics',
            icon: 'qualtrics.svg',
            desc: 'Qualtrics is web based software that allows the user to create surveys and generate reports without having any previous programming knowledge.',
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'https://odu.qualtrics.com/',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'quinnicia',
            name: 'Quinncia',
            icon: 'quinncia.svg',
            desc: 'Get résumé help and schedule a mock interview at Quinncia.',
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'https://quinncia.io/access',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'recWell',
            name: 'RecWell Portal',
            icon: 'recWell.svg',
            desc: 'The RecWell app is your one stop shop for all things ODU Recreation & Wellness.',
            isDefaultSelected: true,
            link: 'https://www.odu.edu/recreation-wellness/about/recwell-app',
            linkTarget: '_blank',
            isODU: true,
            isEVMS: true,
        },
        {
            id: 'retain',
            name: 'Retain',
            icon: 'retain.svg',
            desc: 'Retain is a web platform that streamlines the student experience and makes the staff supporting them more efficient in their work.',
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'https://odu.retain.io/',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'registration',
            name: 'Registration',
            icon: 'registration.svg',
            desc: 'Registration is the website for adding and dropping classes.',
            preSelected: {role: ['STUDENT']},
            link: 'https://xessb.odu.edu/StudentRegistrationSsb/ssb/registration',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'safeColleges',
            name: 'Safe Colleges Training',
            icon: 'safeColleges.svg',
            desc: 'VectorSolutions SafeColleges training offers a variety of self-paced training courses which can be accessed 24/7 and comes with a printable certificate of successful completion.',
            link: 'http://www.odu-va.safecolleges.com/',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'safeRide',
            name: 'Safe Ride',
            icon: 'safeRide.svg',
            desc: 'Safe Ride is a free on-demand evening shuttle service for ODU students, faculty, and staff.',
            preSelected: {campus: ['main'], isHousing: true},
            link: 'https://www.odu.edu/transportation-parking-services/transportation/safe-ride',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'seesEventsDashboard',
            name: 'SEES Events Dashboard',
            icon: 'seesEventsDashboard.svg',
            desc: 'SEES swipe dashboard for SEES staff.',
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'https://analytics.odu.edu/analytics/bi/?pathRef=.public_folders%2FMy+Dashboards+and+Reports%2FStudent+Engagement+and+Enrollment+Services+%28SEES%29%2FSwipe+Dashboards+and+Reports%2FSEES+Swipe+Events+Dashboard&format=HTML&Download=false&prompt=true',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'serviceNow',
            name: 'Service Now',
            icon: 'serviceNow.svg',
            desc: 'Service-Now is the website for the ODU ITS Help Desk, where users can create a trouble ticket or request IT services.',
            preSelected: {campus: ['main']},
            isDefaultSelected: true,
            link: 'https://oduprod.service-now.com',
            linkTarget: '_blank',
            isODU: true,
            isEVMS: true
        },
        {
            id: 'teams',
            name: 'Teams',
            icon: 'teams.svg',
            desc: 'Microsoft Teams is a collaboration platform that combines persistent chat, file collaboration, instant messaging, and meetings in a single platform available free to all students, faculty, and staff.',
            isDefaultSelected: true,
            link: 'http://teams.microsoft.com/',
            linkTarget: '_blank',
            isODU: true,
            isEVMS: true,
        },
        {
            id: 'transfermation',
            name: 'TRANSFERmation',
            icon: 'transfermation.svg',
            desc: 'Monarch TRANSFERmation is a massive online database of transfer information, including courses from other institutions that have been reviewed for credit.',
            link: 'https://www.odu.edu/transfer/transfermation',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'transloc',
            name: 'Transloc',
            icon: 'transloc.svg',
            desc: 'Get the Transloc app to see bus routes, schedules, and request the Safe Ride shuttle.',
            preSelected: {campus: ['main'], isHousing: true},
            link: 'https://transloc.com/app/',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'tuitionCalculator',
            name: 'Tuition Calculator',
            icon: 'tuitionCalculator.svg',
            desc: 'Use the tuition calculator to estimate cost of tuition, room & board, fees, and other charges based on current rates.',
            preSelected: {role: ['STUDENT']},
            link: 'https://itsapps.odu.edu/calculator/',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'odu_upToDate',
            name: 'UpToDate - ODU',
            icon: 'upToDate.svg',
            desc: 'A worldwide online clinical community.',
            isDefaultSelected: false,
            link: 'https://login.proxy.lib.odu.edu/login?url=https://www.uptodate.com',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'universityLibraries',
            name: 'University Libraries',
            icon: 'universityLibraries.svg',
            desc: 'The ODU Libraries exist to inspire and empower the ODU Community to learn, grow, and create.',
            isDefaultSelected: false,
            link: 'https://www.odu.edu/library',
            linkTarget: '_blank',
            preSelected: {role: ['STUDENT']},
            isODU: true
        },
        {
            id: 'vpn',
            name: 'VPN (GlobalProtect)',
            icon: 'vpn.svg',
            desc: 'The GlobalProtect VPN is a secure way for faculty and staff to access sensitive resources such as Banner remotely or from Wi-Fi.',
            preSelected: {role: ['FACULTY', 'STAFF']},
            link: 'https://www.odu.edu/information-technology-services/vpn/vpnclient',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'webTimeEntry',
            name: 'Web Time Entry',
            icon: 'webTimekeeping.svg',
            desc: 'Web Time Entry is used by ODU staff and student workers to submit timesheets, see pay stubs, download tax forms, etc.',
            preSelected: {role: ['STUWORKER']},
            link: 'https://xessb.odu.edu:7005/EmployeeSelfService/ssb/hrDashboard#/hrDashboard',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'wordpress',
            name: 'Wordpress',
            icon: 'wordpress.svg',
            desc: 'WordPress is an online toolset that allows users to create and publish their own websites.',
            preSelected: {role: ['STUDENT', 'INSTFAC']},
            link: 'https://wp.odu.edu/wp-login.php?action=shibboleth',
            linkTarget: '_blank',
            isODU: true
        },
        {
            id: 'zoom',
            name: 'Zoom',
            icon: 'zoom.svg',
            desc: 'Zoom is a video conferencing platform that can be used through a computer desktop or mobile app and allows users to connect online for video conference meetings, classes, and other University events.',
            isDefaultSelected: true,
            link: 'https://odu.zoom.us/',
            linkTarget: '_blank',
            isODU: true,
            isEVMS: true
        },
        //
        //EVMS STARTS HERE
        //
        {
            id: 'evhsc_academicMediaPortal',
            name: 'Academic Media Portal - VHS',
            icon: 'academicMediaPortal.svg',
            desc: 'Academic Media Portal is a the link to Accordent Video Portal containing course and additional content.',
            isDefaultSelected: false,
            link: 'https://prmm.evms.edu',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_acuityStarSystem',
            name: 'Acuity STAR System',
            icon: 'acuityStarSystem.svg',
            desc: 'Supports the capture, analysis and reporting of school members’ teaching, research, administrative, academic clinical and personal activities throughout their careers.',
            preSelected: {
                role: ['EVHSC_STAFF', 'EVMS_FAC', 'MEDICAL_RESIDENT', 'EVMC_STAFF', 'EVMC_FAC']
            },
            isDefaultSelected: false,
            link: 'https://evms.activityreporter.ca/',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_audioVisualMySupportHub',
            name: 'Audiovisual mySupport',
            icon: 'avMySupportHub.svg',
            desc: 'The EVMTS site is dedicated to helping you, communicate with the Media Services team. This site also covers AV systems, applications, frequently asked questions and headlines. Available on campus only.',
            preSelected: {role: ['EVHSC', 'EVMC']},
            isDefaultSelected: false,
            link: 'https://mavts.evms.edu/evmts',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_blackboard',
            name: 'Blackboard - VHS',
            icon: 'blackboard.svg',
            desc: 'Learning Management System for EVHSC.',
            preSelected: {role: ['EVHSC', 'EVMC']},
            isDefaultSelected: false,
            link: 'https://evms.blackboard.com/webapps/login/',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'brickellLibrary',
            name: 'Brickell Library',
            icon: 'brickellLibrary.svg',
            desc: 'The Brickell Medical Sciences Library is a state-of-the-art facility housing the school\'s resource collections, two electronic classrooms, a large video-conferencing room, a history of medicine area, and a blend of individual and group study areas.',
            preSelected: {role: ['EVHSC', 'EVMC']},
            isDefaultSelected: false,
            link: 'https://www.evms.edu/library/',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_attendanceSystem',
            name: 'Attendance System - VHS',
            icon: 'attendanceSystem.svg',
            desc: 'The EVMS Attendance System uses the EVMS After Hours Security System to report on attendance of individuals with an EVMS badge in certain venues on the EVMS Campus.',
            preSelected: {role: ['EVHSC', 'EVMC']},
            isDefaultSelected: false,
            link: 'https://anya.evms.edu/attendance/',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_examsoft',
            name: 'Examsoft',
            icon: 'examSoft.svg',
            desc: 'Download exam software and check exam uploads.',
            preSelected: {role: ['EVHSC', 'EVMC']},
            isDefaultSelected: false,
            link: 'https://www.examsoft.com/evmscea',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_financialAidSystem',
            name: 'Financial Aid System - VHS',
            icon: 'financialAidSystem.svg',
            desc: ' Students can complete the online VHS Financial Aid & Scholarship Application; track the status of the financial aid process, review, accept or decline awards; view history and scheduled disbursements.',
            preSelected: {
                role: [
                    'SCHOOL_OF_MEDICINE_STUDENT',
                    'SCHOOL_OF_HEALTH_PROF_STUDENT'
                ]
            },
            isDefaultSelected: false,
            link: 'https://mySIS.evms.edu',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_functionPoint',
            name: 'Function Point',
            icon: 'functionPoint.svg',
            desc: 'Used by VHS Marketing and Communications for tracking project requests. Through this system, a user can submit job requests, communicate with executives about jobs and track job status.',
            preSelected: {
                role: ['EVHSC_STAFF', 'EVHSC_FAC', 'MEDICAL_RESIDENT', 'EVMC_STAFF', 'EVMC_FAC']
            },
            isDefaultSelected: false,
            link: 'https://evms.functionpoint.com',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_grades',
            name: 'Grades - VHS',
            icon: 'evmsGrades.svg',
            desc: 'Quiz, Lab, and Test Scores for MD and MM students.',
            preSelected: {
                role: [
                    'SCHOOL_OF_MEDICINE_STUDENT',
                    'SCHOOL_OF_HEALTH_PROF_STUDENT'
                ]
            },
            isDefaultSelected: false,
            link: 'https://mysis.evms.edu',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_hyperionPlanning',
            name: 'Hyperion Planning',
            icon: 'hyperionPlanning.svg',
            desc: 'Enterprise budgeting system.',
            preSelected: {role: ['EVHSC_STAFF', 'EVMC_STAFF']},
            isDefaultSelected: false,
            link: 'https://epm-a616679.epm.ca2.oraclecloud.com/HyperionPlanning/',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_paperCut',
            name: 'PaperCut - VHS',
            icon: 'paperCut.svg',
            desc: "The library gives students a yearly printing allotment.  The library's site has additional information and a link to the on-campus Mobility Print Application.",
            preSelected: {
                role: [
                    'SCHOOL_OF_MEDICINE_STUDENT',
                    'SCHOOL_OF_HEALTH_PROF_STUDENT'
                ]
            },
            isDefaultSelected: false,
            link: 'http://studentprint.evms.net:9163/',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_mobileDeviceByod',
            name: 'Mobile Device BYOD',
            icon: 'mobileByod.svg',
            desc: 'To use your Mobile device on campus, you are required to install Mobile Iron.',
            preSelected: {role: ['EVHSC', 'EVMC']},
            isDefaultSelected: false,
            link: 'https://myportal.evms.edu/byod',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_myPortal',
            name: 'VHS myPortal',
            icon: 'evmsMyPortal.svg',
            desc: 'Information for Legacy EVMS employees regarding legacy practices for reference only.',
            preSelected: {role: ['EVHSC', 'EVMC']},
            isDefaultSelected: false,
            link: 'https://myportal.evms.edu',
            linkTarget: '_blank'
        },
        {
            id: 'evhsc_mySis',
            name: 'mySIS - VHS',
            icon: 'mySis.svg',
            desc: 'Student Information System mySIS web based interface.',
            preSelected: {role: ['EVHSC', 'EVMC']},
            isDefaultSelected: false,
            link: 'https://mysis.evms.edu',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_newInnovations',
            name: 'New Innovations',
            icon: 'newInnovations.svg',
            desc: 'An external database for tracking clinical progress towards meeting program requirements through logging patient encounters, recording duty hours and evaluating Students, Residents, Faculty and Programs.',
            preSelected: {
                role: ['EVHSC_STAFF', 'EVHSC_FAC', 'MEDICAL_RESIDENT', 'EVMC_STAFF', 'EVMC_FAC']
            },
            isDefaultSelected: false,
            link: 'https://www.new-innov.com/Login/Login.aspx',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_resourceScheduler',
            name: 'Resource Scheduler',
            icon: 'resourceScheduler.svg',
            desc: 'Resource Scheduler (RS) is the venue management application at VHS it is used to schedule all venues as well as support services: Media & Audio Visual Technical Services, Police & Public Safety, Housekeeping, Maintenance, IT and Special Events.',
            preSelected: {role: ['EVHSC_STAFF', 'EVHSC_FAC','SCHOOL_OF_MEDICINE_STUDENT',
            'SCHOOL_OF_HEALTH_PROF_STUDENT', 'EVMC_STAFF', 'EVMC_FAC' ]},
            isDefaultSelected: false,
            link: 'https://evms.resourcescheduler.net/resourcescheduler/',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_sendIt',
            name: 'Send It',
            icon: 'sendIt.svg',
            desc: 'Used to send large files.',
            preSelected: {role: ['EVHSC', 'EVMC']},
            isDefaultSelected: false,
            link: 'http://sendit.evms.edu/ ',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_sharePoint',
            name: 'SharePoint - VHS',
            icon: 'sharePoint.svg',
            desc: 'SharePoint is a secure place to store, organize, share, and access information from any device.',
            preSelected: {role: ['EVHSC', 'EVMC']},
            isDefaultSelected: false,
            link: 'https://phasma.evms.edu',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_skillsoftPercipioElearningPortal',
            // name: 'Skillsoft Percipio E-Learning Portal',
            name: 'Skillsoft Percipio Portal',
            icon: 'percipio.svg',
            desc: 'Skillsoft Percipio is an immersive learning experience lets you watch, read, listen, and practice – from any device, at any time. Learn new skills or fine-tune the ones you have in everything from project management and agile, to data science and machine learning.',
            preSelected: {
                role: ['EVHSC_STAFF', 'EVHSC_FAC', 'MEDICAL_RESIDENT', 'EVMC_STAFF', 'EVMC_FAC']
            },
            isDefaultSelected: false,
            link: 'https://evms.percipio.com/login.html#/',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_terminalFour',
            // name: 'TerminalFour Website Manager',
            name: 'T4 Web Manager',
            icon: 'terminalFour.svg',
            desc: 'Content Management System used to add and update webpages for www.evms.edu and myportal.evms.edu',
            preSelected: {role: ['EVHSC_STAFF', 'EVHSC_FAC', 'EVMC_STAFF', 'EVMC_FAC']},
            isDefaultSelected: false,
            link: 'https://t4.evms.edu',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_turnItIn',
            name: 'Turn It In',
            icon: 'turnItIn.svg',
            desc: 'Plagirism detection.',
            preSelected: {role: ['EVHSC_STAFF', 'EVHSC_FAC', 'EVMC_STAFF', 'EVMC_FAC']},
            isDefaultSelected: false,
            link: 'http://www.turnitin.com/static/index.html',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_upToDate',
            name: 'UpToDate - VHS',
            icon: 'upToDate.svg',
            desc: 'A worldwide online clinical community.',
            preSelected: {role: ['EVHSC_STAFF', 'EVHSC_FAC', 'EVMC_FAC', 'EVMC_STAFF']},
            isDefaultSelected: false,
            link: 'https://evms.idm.oclc.org/login?url=https://www.uptodate.com/contents/search?unid=^u&srcsys=EZPX12388',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_usaNotify',
            name: 'USA Notify',
            icon: 'usaNotify.svg',
            desc: 'Used by practice managers to view patient appointment reminders and cancelled appointments.',
            preSelected: {role: ['EVHSC_STAFF', 'EVHSC_FAC', 'EVMC_STAFF', 'EVMC_FAC']},
            isDefaultSelected: false,
            link: 'https://evms.usanotify.com/admin/index.php',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_m4MedicalEducationElectivesCatalog',
            // name: 'Med Education Electives Catalog',
            name: 'Med Electives Catalog',
            icon: 'medicalElectiveCatalog.svg',
            desc: 'The Electives Catalog serves as a resource to assist students in scheduling their M4 year. Students can view the available electives and request electives through the catalog.',
            preSelected: {
                role: [
                    'SCHOOL_OF_MEDICINE_STUDENT',
                    'SCHOOL_OF_HEALTH_PROF_STUDENT'
                ]
            },
            isDefaultSelected: false,
            link: 'https://c0ctb249.caspio.com/dp/402c3000402b8befd0a64735b79e',
            linkTarget: '_blank',
            isEVMS: true
        },
        {
            id: 'evhsc_webmail',
            name: 'Web Mail',
            icon: 'outlook.svg',
            desc: 'Access VHS email through the web.',
            preSelected: { role: ['EVHSC', 'EVMC'] },
            isDefaultSelected: false,
            link: 'https://mail.evms.edu/owa/auth/logon.aspx?replaceCurrent=1&url=https%3a%2f%2fmail.evms.edu%2fowa%2f',
            linkTarget: '_blank',
            isEVMS: true
        }
    ]
};

export const WidgetConfigs = {
    Version: 1.5,
    Widgets: [
        {
            id: 'widget__notifications',
            title: 'Notifications',
            desc: 'With the Notifications widget, you can effortlessly receive application notifications, ensuring you stay informed about important updates and events.',
            required: [{}],
            section: 1,
            isNoDrag: true,
            isDeleted: true,
            // defaultDisplay: all,
        },
        {
            id: 'widget__myApps',
            title: 'myApps',
            desc: 'Effortlessly manage your favorite apps and discover new ones! Seamlessly select and prioritize from a comprehensive list, with special recommendations tailored for students and faculty/staff members.',
            required: [{}],
            section: 1,
            // defaultDisplay: all,
        },
        // {id: 'widget__myCalendar', title: 'myCalendar', desc: 'some desc goes here in a somewhat long sentence like this one', required: [{}], section: 1},
        {
            id: 'widget__myCourses',
            title: 'myCourses',
            desc: 'Easily access all your enrolled courses or those you are teaching in one place! Quickly navigate to the course collaboration tools with convenient links provided alongside each course listing.',
            required: [{}],
            section: 1,
            defaultDisplay: {role: ['INSTFAC', 'STUDENT', 'ADJUNCT']},
        },
        {
            id: 'widget__myNews',
            title: 'myNews',
            desc: 'Stay informed with the latest headlines and dive deeper into stories that matter to you.',
            required: [{}],
            section: 1,
            // defaultDisplay: all,
        },
        {
            id: 'widget__announcements',
            title: 'Announcements',
            desc: "Stay in the loop with our university announcements widget with tabs tailored for student or faculty/staff, ensuring you're always informed about the lastest University events.",
            required: [{}],
            section: 1,
           // defaultDisplay: all,
        },
        // {id: 'widget__myTasks', title: 'myTasks', desc: 'some desc goes here in a somewhat long sentence like this one', required: [{}], section: 1},
        {
            id: 'widget__courseTutoring',
            title: 'Course Tutoring',
            desc: 'Seamlessly integrating class search functionality with tutoring resources, this widget offers students a convenient tool to enhance their learning experience. Find tutoring spots with links to information and maps for easy navigation.',
            required: [{}],
            section: 1,
            defaultDisplay: {role: ['INSTFAC', 'STUDENT']},
        },
        {
            id: 'widget__mySchedule',
            title: 'Up Next',
            desc: 'Stay organized with a glance at your next 5 schedule items, complete with locations or Zoom links, ensuring you never miss a class or appointment. ',
            required: [{}],
            section: 2,
            //TODO: change when more than just banner schedule returned from api
            defaultDisplay: {role: ['INSTFAC', 'STUDENT']},
        },
        {
            id: 'widget__myRegistration',
            title: 'myRegistration',
            desc: 'Get a comprehensive view of any holds on your account and see your time ticket well in advance of the registration window, allowing ample time for planning and ensuring a smooth registration process for upcoming semesters.',
            required: [{}],
            section: 2,
            defaultDisplay: {role: ['STUDENT']},
        },
        {
            id: 'widget__myBalances',
            title: 'myBalances',
            desc: 'Check your Monarch plus, laundry, and tuition balances all in one place, providing a comprehensive overview of your balances.',
            required: [{}],
            section: 2,
            // defaultDisplay: {role: ['STUDENT']}, 
        },
        {
            id: 'widget__myPrograms',
            title: 'myPrograms',
            desc: "Track your academic progress by viewing the number of completed credits, current enrollments, next semester's courses, GPA, and declared major.",
            required: [{}],
            section: 2,
            //TODO: update when alumni role developed
            defaultDisplay: {role: ['STUDENT']},
        },
        // {id: 'widget__myHousing', title: 'myHousing', desc: 'some desc goes here in a somewhat long sentence like this one', required: [{}], section: 2},
        {
            id: 'widget__myAdvisingTeam',
            title: 'myAdvisingTeam',
            desc: 'Access contact information for your advisors effortlessly, facilitating communication and support throughout your academic journey.',
            required: [{}],
            section: 2,
            defaultDisplay: {role: ['STUDENT']},
        },
        {
            id: 'widget__universityLibraries',
            title: 'University Libraries',
            desc: 'Explore information about ODU and VHS libraries, including phone numbers and links to useful apps, empowering access to valuable academic resources',
            required: [{}],
            section: 2,
            // defaultDisplay: all,
        },
        {
            id: 'widget__placesAndHours',
            title: 'Places & Hours',
            desc: 'Access real-time information about campus facilities to check current operating statuses, view locations and maps, and explore detailed operating hours for each venue.',
            required: [{}],
            section: 2,
            // defaultDisplay: all,
        },
        {
            id: 'widget__systemStatus',
            title: 'System Status',
            desc: 'Stay informed about the operational status of essential systems like Outlook, LeoOnline, and Canvas.',
            required: [{}],
            section: 3,
            // defaultDisplay: all,
        },
        {
            id: 'widget__safety',
            title: 'Safety',
            desc: 'Get quick access to essential contact information for university, VHS, and Tri-Cities centers, ensuring you can swiftly reach out for assistance in emergency situations or to address safety concerns.',
            required: [{}],
            section: 3,
            // defaultDisplay: all,
        },
        {
            id: 'widget__myBookmarks',
            title: 'myBookmarks',
            desc: 'Effortlessly save and edit bookmarks for quick access to frequently visited websites or resources, streamlining your digital workflow.',
            required: [{}],
            section: 3,
            // defaultDisplay: all,
        },
        {
            id: 'widget__myContacts',
            title: 'myContacts',
            desc: 'Search the university directory and save essential contacts for easy communication and networking within the academic community.',
            required: [{}],
            section: 3,
            // defaultDisplay: all,
        },
        {
            id: 'widget__myDining',
            title: 'myDining',
            desc: 'Monitor dining balances and access links to campus dining place menus, enabling you to convenient meal planning and access to food options.',
            required: [{}],
            section: 3,
            defaultDisplay: {campus: ['main'], role: ['STUDENT']},
        },
        {
            id: 'widget__itsHelp',
            title: 'ITS Help',
            desc: 'Quickly obtain contact information for the Information Technology Help Desk, ensuring prompt assistance with any technical issues or queries.',
            required: [{}],
            section: 3,
            // defaultDisplay: all,
        },
        // {id: 'widget__universityPoll', title: 'University Poll', desc: 'some desc goes here in a somewhat long sentence like this one', required: [{}], section: 3},
        {
            id: 'widget__resources',
            title: 'Resources',
            desc: 'Access a curated list of helpful links, providing valuable resources and information to support your academic and personal needs.',
            required: [{}],
            section: 3,
            // defaultDisplay: all,
        },
        {
            id: 'widget__evhscResources',
            title: 'EVHSC Resources',
            desc: 'Access a list of EVHSC related links, providing valuable medical resources and other EVHSC related materials.',
            required: [{}],
            section: 3,
            defaultDisplay: {role: ['EVHSC_FAC', 'EVHSC_STAFF', 'MEDICAL_RESIDENT', 'SCHOOL_OF_MEDICINE_STUDENT', 'SCHOOL_OF_HEALTH_PROF_STUDENT', 'EVMC_STAFF', 'EVMC_FAC']},
        }
    ]
};

export const DefaultWidgetOrder = [
    [
        'widget__myApps',
        'widget__myCourses',
        'widget__myNews',
        'widget__announcements',
        'widget__courseTutoring'
    ],
    [
        'widget__mySchedule',
        'widget__myRegistration',
        'widget__myBalances',
        'widget__myPrograms',
        'widget__myAdvisingTeam',
        'widget__universityLibraries',
        'widget__placesAndHours'
    ],
    [
        'widget__systemStatus',
        'widget__safety',
        'widget__myBookmarks',
        'widget__myContacts',
        'widget__myDining',
        'widget__itsHelp',
        'widget__resources',
        'widget__evhscResources'
    ]
];

export const WidgetVersionHistory = {
    1.0: {
        Added: [
            'widget__notifications',
            'widget__myApps',
            'widget__myCourses',
            'widget__myNews',
            'widget__announcements',
            'widget__courseTutoring',
            'widget__mySchedule',
            'widget__myRegistration',
            'widget__myPrograms',
            'widget__myAdvisingTeam',
            'widget__universityLibraries',
            'widget__placesAndHours',
            'widget__systemStatus',
            'widget__safety',
            'widget__myBookmarks',
            'widget__myContacts',
            'widget__myDining',
            'widget__itsHelp',
            'widget__resources'
        ],
        Deleted: []
    },
    1.1: {
        Added: ['widget__myBalances'],
        Deleted: []
    },
    1.2: {
        Added: [],
        Deleted: ['widget__notifications']
    },
    1.3: {
        Added: ['widget__evhscResources'],
        Deleted: []
    },
    //Empty version is to refresh all the widget configs of each user. It is useful in cases where widget data like section, defaultDisplay gets updated
    1.4: {
        Added: [],
        Deleted: []
    },
    1.5: {
        Added: [],
        Deleted: []
    }
};

export const AppVersionHistory = {
    1.0: {
        Added: [],
        Deleted: []
    },
    2.0: {
        Added: [
            'evhsc_academicMediaPortal',
            'evhsc_acuityStarSystem',
            'evhsc_audioVisualMySupportHub',
            'evhsc_blackboard',
            'evhsc_attendanceSystem',
            'evhsc_examsoft',
            'evhsc_financialAidSystem',
            'evhsc_functionPoint',
            'evhsc_grades',
            'evhsc_hyperionPlanning',
            'evhsc_paperCut',
            'evhsc_mobileDeviceByod',
            'evhsc_myPortal',
            'evhsc_mySis',
            'evhsc_newInnovations',
            'evhsc_resourceScheduler',
            'evhsc_sendIt',
            'evhsc_sharePoint',
            'evhsc_skillsoftPercipioElearningPortal',
            'evhsc_terminalFour',
            'evhsc_turnItIn',
            'evhsc_upToDate',
            'evhsc_usaNotify',
            'evhsc_m4MedicalEducationElectivesCatalog',
            'evhsc_webmail',
        ],
        Deleted: []
    },
    2.1: {
        Added: ['finAidPortal'],
        Deleted: []
    },
    2.2: {
        Added: ['monarchAdvisingPortal'],
        Deleted: []
    },
    2.3: {
        Added: ['odu_upToDate'],
        Deleted: []
    },
    2.4: {
        Added: ['universityLibraries', 'brickellLibrary'],
        Deleted: []
    },
    2.5: {
        Added: ['safeColleges'],
        Deleted: []
    },
    2.6: {
        Added: ['get'],
        Deleted: []
    },
};
