import React, {Component} from 'react';
import Widget from '../common/Widget';
// import { Container, Row, Col } from 'reactstrap';
import {
    IconButton,
    InputAdornment,
    Card,
    Typography,
    List,
    ListItem,
    OutlinedInput,
    Skeleton
} from '@mui/material';
import Place from '../common/PlaceSummary';
import PlaceCard from '../common/PlaceCard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/pro-light-svg-icons';
import {faClose} from '@fortawesome/pro-light-svg-icons';
import PlaceCategories from './PlaceCategories';
import {getData} from '../../DataAccessLayer';
import {placesAndHours} from '../../DataAccessLayer/services';
import {toggleAllCategories} from './actions';
import {connect} from 'react-redux';
import _ from 'lodash';
import ApiErrorMessage from '../common/ApiErrorMessage';

class PlacesAndHours extends Component {
    state = {
        isExpand: true,
        menu: [
            {
                id: 'placesAndHours_expandAll',
                title: 'Expand All Categories',
                onClick: () => this.toggleAllCategoriesState(true)
            },
            {
                id: 'placesAndHours_collapseAll',
                title: 'Collapse All Categories',
                onClick: () => this.toggleAllCategoriesState(false)
            }
            //TODO: enable in V1.1
            // {id: 'placesAndHours_hide', title: 'Hide'},
        ],
        data: [],

        searchContent: '',

        searchResults: [],

        isLoading: false,

        isError: false,

        onSearch: false
    };

    componentDidMount() {
        this.loadPlacesAndHours();
    }

    //O(1) Fetches all places and hours
    loadPlacesAndHours = async () => {
        this.setState({isLoading: true, isError: false});
        try {
            const data = await getData(placesAndHours, true);

            this.setState({data});
        } catch (err) {
            this.setState({isError: true});
        } finally {
            this.setState({isLoading: false});
        }
    };

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    /**
     * Θ(N*M) - Toggles the expanded state of all categories and sets places within those categories based on the `isExpanded` parameter.
     */

    toggleAllCategoriesState = isExpanded => {
        this.props.toggleAllCategories(
            this.state.data.reduce((newState, category) => {
                // Directly set the expanded state for the category
                newState[category.id] = isExpanded;

                // Directly set the expanded state for all places within the category to false
                category.places.forEach(place => (newState[place.id] = false));

                return newState;
            }, {})
        );
    };

    handleClearSearch = () => {
        this.setState({searchResults: [], searchContent: '', onSearch: false});
    };

    handleSearch = () => {
        let searchResults = [];

        const searchContent = this.state.searchContent.trim();

        if (searchContent) {
            searchResults = this.state.data.reduce((acc, item) => {
                // Perform a case-insensitive search
                const matches = item.places.filter(place =>
                    place.title
                        .toLowerCase()
                        .includes(searchContent.toLowerCase())
                );

                // Concatenate any found matches to the accumulator
                return acc.concat(matches);
            }, []);
        }
        this.setState({
            searchResults,
            onSearch: true
        });
    };

    handleKeyDownSearch = event => {
        if (event.key === 'Enter') {
            this.handleSearch();
        }
    };

    render() {
        const {isLoading, data, isError} = this.state;

        return (
            <>
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title,
                        isTitleStylized: this.props.isTitleStylized,
                        menu: this.state.menu
                    }}
                    hasScrollY
                    className="myOdu__placesAndHours"
                    {...this.props}
                >
                    <div id="myOdu__placesAndHours_wrapper" className="wrapper">
                        {isLoading ? (
                            <Skeleton
                                id={this.props.widget.id + '__loadingSkeleton'}
                                variant="rectangular"
                                height={300}
                            />
                        ) : data.length === 0 && !isLoading ? (
                            isError ? (
                                <ApiErrorMessage
                                    id={this.props.widget.id + '__apiErrorMessage'}
                                    widgetName={this.props.title}
                                    reload={this.loadPlacesAndHours}
                                />
                            ) : (
                                <Typography
                                    id={this.props.widget.id + '__notFoundMessage'}
                                    component="p"
                                    variant="small"
                                >
                                    No places and hours found.
                                </Typography>
                            )
                        ) : (
                            <>
                                <Card

                                    className="search"
                                    elevation={0}
                                    id={this.props.widget.id + '__card_search'}
                                >
                                    <OutlinedInput
                                        id={this.props.widget.id + '__input_search'}
                                        value={this.state.searchContent}
                                        onChange={e =>
                                            this.setState({
                                                searchContent: e.target.value
                                            })
                                        }
                                        fullWidth
                                        size="small"
                                        label="Search Places and Hours"
                                        // sx={textFieldCss}
                                        className="myOdu__hideFloatLabel myOdu__border"
                                        placeholder="Search Places and Hours"
                                        onKeyDown={this.handleKeyDownSearch}
                                        endAdornment={
                                            <InputAdornment
                                                position="end"
                                                id={
                                                    this.props.widget.id +
                                                    '__inputAdornment_search'
                                                }
                                            >
                                                {this.state.searchResults.length > 0 || this.state.onSearch ? (
                                                    <IconButton
                                                        id={ this.props.widget.id + '__iconButton_search_clear' }
                                                        aria-label="Clear Search Places and Hours"
                                                        edge="end"
                                                        onClick={ this.handleClearSearch }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faClose}
                                                            size="xs"
                                                            id={ this.props.widget .id + '__icon_search_clear' }
                                                        />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton
                                                        id={ this.props.widget.id + '__iconButton_search' }
                                                        aria-label="Search Places and Hours"
                                                        edge="end"
                                                        onClick={this.handleSearch}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faSearch}
                                                            size="xs"
                                                            id={ this.props.widget .id + '__icon_search' }
                                                        />
                                                    </IconButton>
                                                )}
                                            </InputAdornment>
                                        }
                                    />

                                    {/* //if search results */}

                                    {!_.isEmpty(this.state.searchResults) ? (
                                        <>
                                            <Typography
                                                component="h3"
                                                variant="h6"
                                                sx={{mt: 2, mb: 1}}
                                                id={ this.props.widget.id + '__header_searchResults' }
                                            >
                                                Search Results
                                            </Typography>

                                            {this.state.searchResults.map(
                                                result => (
                                                    <Card
                                                        className="myOdu__border"
                                                        elevation="0"
                                                        sx={{mb: 1}}
                                                        id={ this.props.widget.id + '__card_searchResults_' + result.id }
                                                    >
                                                        <PlaceCard
                                                            id={ this.props.widget .id + '__placeCard_searchResults_' + result.id }
                                                            data={{
                                                                location: result
                                                            }}
                                                        />
                                                    </Card>
                                                )
                                            )}
                                        </>
                                    ) : (
                                        this.state.onSearch && (
                                            <Typography
                                                component="p"
                                                variant="small"
                                                sx={{pt: 1}}
                                            >
                                                No search results found.
                                            </Typography>
                                        )
                                    )}
                                </Card>

                                <List
                                    id={this.props.widget.id + '__list'}
                                    className="categories"
                                >
                                    {this.state.data.map(category => (
                                        <ListItem
                                            id={ this.props.widget.id + '__listItem_' + category.id }
                                            divider
                                            disableGutters
                                            key={ this.props.widget.id + '__listItem_' + category.id }
                                        >
                                            <PlaceCategories
                                                id={ this.props.widget.id + '__placeCategories' }
                                                data={{category: category}}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </>
                        )}
                    </div>
                </Widget>
            </>
        );
    }
}

const mapStateToProps = state => ({
    expandedCategories: state.expandedCategories
});

const mapDispatchToProps = {
    toggleAllCategories
};

export default connect(mapStateToProps, mapDispatchToProps)(PlacesAndHours);
