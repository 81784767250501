import React, {Component} from 'react';
import {Stack, Typography, Box} from '@mui/material';
import {Time} from '../../common/MomentTime';
import {FormTextArea} from '../../common/Form/FormTextArea';
import {FormButton} from '../../common/Form/FormButton';

class FeedbackDetails extends Component {
    state = {internalNote: ''};

    componentDidMount() {
        this.setState({internalNote: this.props.data?.note || ''});
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.data?.note !== this.props.data?.note ||
            prevProps.data?.timestamp !== this.props.data?.timestamp
        ) {
            this.setState({internalNote: this.props.data?.note || ''});
        }
    }

    render() {
        const {data} = this.props;
        const {
            midas,
            bugDetail,
            changeDetail,
            ideaDetail,
            metadata,
            otherDetail,
            pageUrl,
            rating,
            timestamp,
            note
        } = data || {};

        const {internalNote} = this.state;

        return (
            <Stack spacing={1}>
                <Typography component="h3" variant="h3">
                    Details
                </Typography>

                <Stack direction="row">
                    <Typography
                        component="label"
                        variant="p"
                        className="myOdu__label"
                        sx={{mr: 1}}
                    >
                        User MIDAS
                    </Typography>
                    <Typography component="p">{midas}</Typography>
                </Stack>

                <Stack direction="row">
                    <Typography
                        component="label"
                        className="myOdu__label"
                        sx={{mr: 1}}
                    >
                        Time Submitted
                    </Typography>
                    <Typography component="p">
                        {timestamp
                            ? Time.University(timestamp).format(
                                  'M/DD/YYYY h:mm:ss A'
                              )
                            : '-'}
                    </Typography>
                </Stack>

                <Stack direction="row">
                    <Typography
                        component="label"
                        className="myOdu__label"
                        sx={{mr: 1}}
                    >
                        Rating
                    </Typography>
                    <Typography component="p">
                        {rating ? rating : '-'}
                    </Typography>
                </Stack>

                <Box className="myOdu__box bordered" sx={{m: 0}}>
                    <Typography component="label" className="myOdu__label">
                        Bug Details
                    </Typography>
                    <Stack direction="row" sx={{my: 1}}>
                        <Typography
                            component="p"
                            variant="smaller"
                            sx={{mr: 1, fontWeight: 'bold'}}
                        >
                            Page URL
                        </Typography>
                        <Typography component="p" variant="smaller">
                            {pageUrl ? pageUrl : '-'}
                        </Typography>
                    </Stack>

                    <Typography component="p" variant="smaller">
                        {bugDetail ? bugDetail : '-'}
                    </Typography>
                </Box>
                <Box className="myOdu__box bordered" sx={{m: 0}}>
                    <Typography component="label" className="myOdu__label">
                        Idea Details
                    </Typography>
                    <Typography component="p" variant="smaller">
                        {ideaDetail ? ideaDetail : '-'}
                    </Typography>
                </Box>
                <Box className="myOdu__box bordered" sx={{m: 0}}>
                    <Typography component="label" className="myOdu__label">
                        Change Details
                    </Typography>
                    <Typography component="p" variant="smaller">
                        {changeDetail ? changeDetail : '-'}
                    </Typography>
                </Box>
                <Box className="myOdu__box bordered" sx={{m: 0}}>
                    <Typography component="label" className="myOdu__label">
                        Other Details
                    </Typography>
                    <Typography component="p" variant="smaller">
                        {otherDetail}
                        {otherDetail ? otherDetail : '-'}
                    </Typography>
                </Box>
                <Box className="myOdu__box bordered" sx={{m: 0}}>
                    <Typography component="label" className="myOdu__label">
                        MetaData
                    </Typography>
                    <Typography component="p" variant="smaller">
                        <div>
                            <pre>{JSON.stringify(metadata, null, 2)}</pre>
                        </div>
                    </Typography>
                </Box>
                <Box className="myOdu__box bordered" sx={{m: 0}}>
                    <Typography component="label" className="myOdu__label">
                        Note
                    </Typography>
                    <FormTextArea
                        inputGridProps={{xs: 12}}
                        maxChars={1000}
                        value={internalNote}
                        onChange={e => this.setState({internalNote: e})}
                        disabled={!(midas && timestamp)}
                    />
                    <Box display="flex" justifyContent="right">
                        <FormButton
                            label="Add Note"
                            onClick={() =>
                                this.props.onSaveNote({
                                    midas,
                                    timestamp,
                                    note: internalNote
                                })
                            }
                            disabled={!internalNote || !(midas && timestamp)}
                        />
                    </Box>
                </Box>
            </Stack>
        );
    }
}

export default FeedbackDetails;
